import styled from 'styled-components';

const getBannerHeight = (props, deviceType) => {
  const headerHeight = props.heightCalculation || (deviceType === 'mobile' ? 80 : 90);
  if (props.bannerFixedRatio === 0) {
    // fixed height
    if (deviceType === 'mobile') {
      return `height: calc(100vh - ${headerHeight}px)`;
    } else {
      return `height: calc(100vh - ${headerHeight}px)`;
    }
  } else {
    // fixed aspect ratio
    if (deviceType === 'mobile') {
      return `height: 0; padding-top: ${(16 / 9) * 100}%;`;
    } else {
      return `height: 0; padding-top: ${(9 / 16) * 100}%;`;
    }
  }
};

export const HeroBannerComponent = styled.main`
  display: flex;
  width: 100%;
  flex: 1;
  position: relative;

  @media screen and (min-width: 767px) {
    ${(props) => getBannerHeight(props, 'desktop')}
  }

  @media screen and (max-width: 766px) {
    ${(props) => getBannerHeight(props, 'mobile')}
  }
`;

export const HeroBannerImage = styled.section`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & > .next-banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;

    @media screen and (max-height: 600px) {
      object-position: center top;
    }
  }
`;

export const HeroBannerInteractivePosition = styled.div`
  position: absolute;
  top: 0;

  @media screen and (min-width: 767px) {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    max-width: 750px;
    flex: 1 1 0%;
    right: 0;
    justify-content: flex-end;
  }

  @media screen and (max-width: 766px) {
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    height: min-content;
  }
`;

export const HeroBannerInteractiveBody = styled.div`
  z-index: 5;
  width: 100%;
  opacity: 0.98;
  box-sizing: border-box;

  @media screen and (min-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    padding: 0 4rem;
    text-align: right;
    height: 100%;
    width: fit-content;
    color: ${(props) => props.fontColor};
    background: transparent
      linear-gradient(90deg, #ffffff00 0%, #ffffffd8 35%, #ffffffe6 100%) 0% 0% no-repeat
      padding-box;
  }

  @media screen and (max-width: 766px) {
    text-align: center;
    padding: 8rem 2.5rem 4rem;
    color: ${(props) => props.fontColor};
    background: transparent linear-gradient(0deg, #ffffff00 0%, #ffffff 100%) 0% 0%
      no-repeat padding-box;
  }

  @media screen and (max-height: 600px) {
    padding: 1rem 2.5rem 2rem;
  }
`;

export const HeroBannerStoreImage = styled.div`
  border-radius: ${(props) => props.roundness};
  overflow: hidden;
  object-fit: cover;
  overflow: hidden;
  position: relative;

  & > .next-store-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  @media screen and (min-width: 767px) {
    height: 77px;
    width: 77px;
    margin-bottom: 20px;
    margin-left: auto;
  }

  @media screen and (max-width: 766px) {
    height: 57px;
    width: 57px;
    margin: 0 auto 20px;
  }
`;

export const HeroBannerHeading = styled.h2`
  width: 100%;
  margin: 0;
  color: inherit;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (min-width: 767px) {
    font-size: 48px;
    margin-bottom: 20px;
    max-width: 550px;
  }

  @media screen and (max-width: 766px) {
    font-size: 27px;
    margin-bottom: 20px;
  }
`;

export const HeroBannerDescription = styled.p`
  margin: 0;
  padding: 0;
  color: inherit;
  opacity: 0.65;
  box-sizing: border-box;
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;

  @media screen and (min-width: 767px) {
    font-size: 16px;
    margin-bottom: 30px;
    max-width: 470px;
  }

  @media screen and (max-width: 766px) {
    font-size: 14px;
    margin-bottom: 20px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ViewMoreSpan = styled.span`
  font-weight: 700;
  cursor: pointer;
  line-height: 1.4;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (min-width: 767px) {
    font-size: 14px;
  }

  @media screen and (max-width: 766px) {
    font-size: 12px;
  }
`;

export const HeroBannerCTAButton = styled.div`
  @media screen and (max-width: 766px) {
    text-align: center;
    width: 100%;

    & > button {
      margin: 0 auto;
    }
  }
`;
